import React from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { clsx } from '../../utils/utils';

import * as styles from './Button.module.scss';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  secondary?: boolean;
  children: string;
}

const Button = ({
  className,
  secondary,
  children,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      className={clsx(className, styles.container, secondary && styles.secondary)}
      {...restProps}
    >
      <span className={styles.children}>{children}</span>
    </button>
  );
};

export default Button;
